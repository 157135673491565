import React from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';
import DropDownContainer from './DropDownContainer';

function TextContent({
  heading,
  subHeading="",
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  customContent,
  btn=false,
  btnText,
  btnRef="/",
  btnIcon=false,
  shiftRow=false,
  img,
  reviewContent= false,
  reviewHeading,
  reviewPara,
  mobileTextCenter=true,
  titleList=false,
  titleListContent=[],
  dropDown=false,
  dropDownList=[],
  marginTop=false,
  sublineIcon=true,
  listPoint=[],
  listPointList,
  adjecentlistPoint=[],
  adjecentlistPointList,
  postBtn=false
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
 const navigate = useNavigate()
  return (
    <div className={`text-content-section standard-padding-space`} style={marginTop ? {paddingTop:0}:{}}>
      <div className='container'>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 px-3 px-lg-4 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className='img-fluid'/>
          </div>
          <div className='col-lg-6'>
            <div className={`text-content ${mobileTextCenter && "text-start text-lg-start"} `} style={{width:"95%"}}>
              <p className='sub-heading mb-0'>{subHeading}</p>
              {/* <div className='paddLeft'> */}
              <div className='paddLef'>
                <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                {/* { sublineIcon && <img src='/images/icons/subline.svg' />} */}
                <p className='sub-heading mb-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                <div className=''>{paragraphs}</div>
                {customContent && <p className='body-paragraph my-1 mb-3' dangerouslySetInnerHTML={{__html:customContent}}></p>}
                <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>
                <p className='body-paragraph my-1 mb-3'>{postContent}</p>
                {
                  titleList && 
                  <div>
                    {
                      titleListContent.map((ele)=>{
                        return(
                        <p className='body-paragraph'>
                          <span style={{color:"#39b4e4",fontSize:"18px",fontWeight:"600"}}>{ele.title}</span>{ele.content}
                        </p>)
                      })
                    }
                  </div>
                }
                {/* {
                
                btn ?
                
                  btnRef == "tel"
                  ?
                  <a href='tel:(416) - 744 - 7417'><button className='genral-btn mt-4 d-flex align-items-center gap-2' >{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button></a>
                  :
                  btnRef == "jnape"?
                  <a href='https://truelifewellnessphysio.janeapp.com/' target='blank'><button className='genral-btn mt-4 d-flex align-items-center gap-2' >{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button></a>
                  :
                  <button className='genral-btn mt-4 d-flex align-items-center gap-2' onClick={()=>{navigate(btnRef)}}>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button>
                :
                <></>} */}
                {
                    btn ?
                      btnRef === "tel" ?
                        <a href='tel:(416) - 744 - 7417' style={{textDecoration:"none"}}><button className='genral-btn mt-4 d-flex align-items-center gap-2'>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button></a>
                      :
                        <button className='genral-btn mt-4 d-flex align-items-center gap-2' onClick={() => { navigate(btnRef) }}>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button>
                    :
                    <></>
                  }
              </div>
              {
                reviewContent &&
                <>
                    <div className='my-2'>
                      <h5 className='sub-heading mb-1' style={{fontSize:"19px"}}>{reviewHeading}</h5>
                      <p className='body-heading mb-0' style={{fontSize:"22px"}}>{reviewPara}</p>
                    </div>
                  
                </>
              }
              {
                  listPointList && 
                  <div className='list-point-container d-flex flex-column gap-3'>
                      {listPoint.map(ele =>{
                          return(
                              <div className='d-flex align-items-start my-0 icon-list gap-2'>
                                  <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet mt-2 '/>
                                  <p className='body-paragraph my-0 list-para'>{ele}</p>
                              </div>)
                      })}
                  </div>
              }
              {
                  adjecentlistPointList && 
                  <div className='list-point-container d-flex flex-column gap-3'>
                    <div className='row'>
                      {adjecentlistPoint.map(ele =>{
                          return(
                            <div className='col-lg-6 '>
                              <div className='d-flex align-items-start my-2 icon-list gap-2'>
                                  <img src='/images/icons/hero-bullets.png' alt="icon" className='list-bullet mt-2 '/>
                                  <p className='body-paragraph list-para my-0'>{ele}</p>
                              </div>
                             </div>
                              )
                      })}
                    </div>

                  </div>
              }
              {
                dropDown &&
                <>
                  {
                    dropDownList.map((ele,ind)=>{
                      return(
                        <>
                        <DropDownContainer
                          title={ele.title}
                          content={ele.content}
                        />
                        {ind < dropDownList.length-1 ? <div className='line-seprator'></div>:<></>}
                        </>
                      )
                    })
                  }
                </>
              }
              {/* {postBtn && <button className='genral-btn mt-4 d-flex align-items-center gap-2' onClick={()=>{navigate(btnRef)}}>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button>} */}
              {
                postBtn ?
                  btnRef === "tel" ?
                    <a href='tel:(416) - 744 - 7417' style={{textDecoration:"none"}}><button className='genral-btn mt-4 d-flex align-items-center gap-2'>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button></a>
                  :
                  btnRef === "jnape" ?
                    <a href='https://truelifewellnessphysio.janeapp.com/' target='_blank' style={{textDecoration:"none"}}><button className='genral-btn mt-4 d-flex align-items-center gap-2'>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button></a>
                  :
                    <button className='genral-btn mt-4 d-flex align-items-center gap-2' onClick={() => { navigate(btnRef) }}>{btnText} {btnIcon && <img className='' src='/images/icons/btn-arrow-icon.svg' alt='arrow' />}</button>
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextContent