import React,{useState} from 'react'
import "./VideoContent.css"
import { useNavigate } from 'react-router-dom';
import Modal from "../Modal/Modal";

function VideoContent({
  img1,
  img2,
  videoSrc1,
  videoSrc2,
  singleVideo=false,
  bottomContent="Our team of registered healthcare practitioners brings years of experience and expertise to the table. Whether you are recovering from an injury, managing a chronic condition, or simply looking to improve your overall well-being, you can trust that you are in good hands with our dedicated professionals.",
  openVideo=true
  }) {
    
    const [modal, setModal] = useState(false);
    const [videSource , setVideoSource] = useState("")

    const toggleModal = (e) => {
      
        setModal((prev)=>{
            return !prev
        });
    };
    return (
      <div className='video-content-section standard-padding-space'>
        {modal ? <Modal status={modal} closeModal={toggleModal} videoSrc={videSource} /> : <></>}
        <div className='container'>
        
        <div className='bg-shade-color '>
          <div className='row low-cont'>
            <div className={`col-lg-${singleVideo?"12":"6"} ${singleVideo ? "px-5":"p-0"}`}>
              <img src={img1} className='img-fluid' style={{cursor:"pointer"}} onClick={()=>{if(openVideo){setModal(true);setVideoSource(videoSrc1)}}}/>
            </div>
            <div className='col-lg-6'>
              <img src={img2} className='img-fluid' style={{cursor:"pointer"}} onClick={()=>{setModal(true);setVideoSource(videoSrc2)}}/>
            </div>
          </div>
        </div>

        <div className='text-content text-center px-2 mt-3'>
          <p className='body-paragraph'>{bottomContent}</p>

        </div>
         
        </div>
      </div>
    )
  }

export default VideoContent